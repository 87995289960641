import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const barLogos = [
  {
    name: "pubsport.png",
    colorVersion: "pubsport-colored.png",
    alt: "pubsport",
  },
  {
    name: "shamrock.png",
    colorVersion: "shamrock-colored.png",
    alt: "shamrock",
  },
  { name: "globe.png", colorVersion: "globe-colored.png", alt: "globe" },
  {
    name: "cafe-runddelen.png",
    colorVersion: "cafe-runddelen-colored.png",
    alt: "cafe-runddelen",
  },
  {
    name: "freddys-bar.png",
    colorVersion: "freddys-bar-colored.png",
    alt: "freddys-bar",
  },
  { name: "the-cav.png", colorVersion: "the-cav-colored.png", alt: "the-cav" },
  { name: "rekom.png", colorVersion: "rekom-colored.png", alt: "rekom" },
  { name: "fr-vi.png", colorVersion: "fr-vi-colored.png", alt: "fr-vi" },
  {
    name: "brew-on-quay.png",
    colorVersion: "brew-on-quay-colored.png",
    alt: "brew-on-quay",
  },
  {
    name: "proud-mary-pub.png",
    colorVersion: "proud-mary-pub-colored.png",
    alt: "proud-mary-pub",
  },
  {
    name: "good-spirits-hospitality.png",
    colorVersion: "good-spirits-hospitality-colored.png",
    alt: "good-spirits-hospitality",
  },
  { name: "the-fox.png", colorVersion: "the-fox-colored.png", alt: "the-fox" },
  {
    name: "malmo-sportbar.png",
    colorVersion: "malmo-sportbar-colored.png",
    alt: "malmo-sportbar",
  },
  { name: "johns.png", colorVersion: "johns-colored.png", alt: "johns" },
  {
    name: "interpool.png",
    colorVersion: "interpool-colored.png",
    alt: "interpool",
  },
  {
    name: "flying-barrel.png",
    colorVersion: "flying-barrel-colored.png",
    alt: "flying-barrel",
  },
  {
    name: "the-kingslander.png",
    colorVersion: "the-kingslander-colored.png",
    alt: "the-kingslander",
  },
  { name: "fagans.png", colorVersion: "fagans-colored.png", alt: "fagans" },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1200, min: 750 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 2,
  },
};

const BarLogosCaroussel = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(!isPaused);
  };

  const handleMouseLeave = () => {
    setIsPaused(!isPaused);
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all 1s linear"
      transitionDuration={1000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      pauseOnHover={true}
      responsive={responsive}
    >
      {barLogos.map((logo, idx) => {
        return (
          <BarLogo
            key={idx}
            name={logo.name}
            colorVersion={logo.colorVersion}
            alt={logo.alt}
            bigAlternative={logo.bigAlternative}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </Carousel>
  );
};

function BarLogo({
  name,
  alt,
  bigAlternative,
  colorVersion,
  onMouseEnter,
  onMouseLeave,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnterLogo = (e) => {
    setIsHovered(true);
    if (onMouseEnter) onMouseEnter(e);
  };

  const handleMouseLeaveLogo = (e) => {
    setIsHovered(false);
    if (onMouseLeave) onMouseLeave(e);
  };

  return (
    <div className="carousel-item">
      <img
        className={
          "frontPage__barLogo " +
          (bigAlternative ? "frontPage__barLogo--onlySmall" : "")
        }
        src={getImage("bar-logos/" + (isHovered ? colorVersion : name))}
        alt={alt}
        onMouseEnter={handleMouseEnterLogo}
        onMouseLeave={handleMouseLeaveLogo}
      />
      {bigAlternative && (
        <img
          className="frontPage__barLogo frontPage__barLogo--onlyBig"
          src={getImage(
            "bar-logos/" + (isHovered ? colorVersion : bigAlternative)
          )}
          alt={alt}
          onMouseEnter={handleMouseEnterLogo}
          onMouseLeave={handleMouseLeaveLogo}
        />
      )}
    </div>
  );
}

function getImage(path) {
  return require("../assets/images/" + path);
}

export default BarLogosCaroussel;
