import React from "react";

const BarLogos = () => {
  return (
    <div className="barLogo">
      <div
        className="d-flex justify-content-between"
        style={{
          marginBottom: "10px"
        }}
      >
        <img
          className="barLogo__bar"
          src={getLogo('globe.png')}
          alt="globe"
        />
        <img
          className="barLogo__bar"
          src={getLogo('malmo-sportbar.png')}
          alt="malmo-sportbar"
        />
        <img
          className="barLogo__bar"
          src={getLogo('flying-barrel.png')}
          alt="flying-barrel"
        />
      </div>
      <div className="d-flex justify-content-between">
        <img
          className="barLogo__bar"
          src={getLogo('shamrock.png')}
          alt="shamrock"
        />
        <img
          className="barLogo__bar"
          src={getLogo('pubsport.png')}
          alt="pubsport"
        />
        <img
          className="barLogo__bar"
          src={getLogo('good-spirits-hospitality.png')}
          alt="good-spirits-hospitality"
        />
      </div>
    </div>
  );
}

function getLogo(path) {
  return require('../assets/images/bar-logos/' + path)
}

export default BarLogos;