import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 750 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
  },
};

const testimonials = [
  {
    image: "Karina_Kjær.png",
    name: "Karina Kjær",
    position: "Pub og Sport",
    message: `Sport Compass is a very user friendly platform that has optimized our
          sports program in every way. The customer service is impeccable and
          the overall evaluation of Sport Compass is very good. I will
          definitely recommend it.`,
  },
  {
    image: "Naoise_Mac_Namara.png",
    name: "Naoise Mac Namara",
    position: "The Shamrock Inn",
    message: `I met Calin and the Sport Compass fellas when they were just starting
          out. They pitched the idea and I liked it! It's great to see how they
          built something starting from a simple idea. Before I had to make time
          every Monday morning to do the fixtures section for the website and
          Facebook. Now, I just do it with a few clicks here and there in about
          five minutes.`,
  },
  {
    image: "Jakob_Heiberg.png",
    name: "Jakob Heiberg",
    position: "Gorm`s Pizza",
    message: `I know Calin and Simon from the Start-up accelerator program ‘Go Grow’
          at Copenhagen Business School where they are participating with their
          exciting company Sport Compass. I am impressed that they have been
          able to get ‘Save My Local Business’ up and running in such a short
          time and hope that they will be able to help lots of companies...`,
  },
  {
    image: "default_profile_pic.png",
    name: "Fröling Adamek",
    position: "Malmö Sportbar",
    message: `We are a pretty big and old bar with lots of activities and many TV screens
          so I thought people would just show up if I turn on the sports on TV. But I was wrong!
          You need to let people know in time, regularly, correctly, offline and online. If you
          do not do that, you are basically throwing money out of the window. That’s when
          Sport Compass comes in...`,
  },
  {
    image: "default_profile_pic.png",
    name: "Micke Fröling Adamek",
    position: "Interpool",
    message: `En bra smidig hjälpreda för en sportbar,
          SportCompass hjälper oss att organisera vilka sportevent vi visar,
          och det blir enkelt att hålla hemsida och sociala medier
          uppdaterade genom automatiska postningar, Stor plus är även att man
          får ett bra schema som gör det lätt för personalen att se vilken match som skall visas när och på vilken kanel`,
  },
  {
    image: "default_profile_pic.png",
    name: "Colin Maguire",
    position: "Good spirits",
    message: `A must have for all sports bars. Since using Sport Compass
          we haven't missed a sporting event and have seen increased trade
          on the bespoke/alternative sporting events. Calin/Simon have been
          fantastic and catered to all my needs in terms of customization & content.
          Very approachable and great communication back and forth.`,
  },
  {
    image: "default_profile_pic.png",
    name: "Jimmy Streit",
    position: "Frederik VI",
    message: `Although Frederik VI is not a sports bar, we regularly show livesport
          for our customers who enjoy it. In order to get the maximum value out of our
          TV subscription we use Sport Compass. In the system I can easily select the games
          I am interested in and then the software does the rest. I can recommend
          Sport Compass to anyone who has a TV subscription at their bar...`,
  },
  {
    image: "default_profile_pic.png",
    name: "Jakob Petersen",
    position: "Skum Ølbar",
    message: `Jeg begyndte at bruge Sport Compass i sommeren 2021, og jeg kan bestemt
          anbefale det til enhver bar, der har tv -skærme. Kontakt dem, da de ligger
          inde med alt hvad du skal bruge indenfor Livesport database, kanaler,
          sociale medier, hjemmeside - alt på ét sted! Tak til Sport Compass! SKUM Ølbar, Jakob`,
  },
  {
    image: "default_profile_pic.png",
    name: "Jimmy Poolpub",
    position: "Pool-Pub",
    message: `Her på POOL-PUB vil vi bruge tid på det, der betyder mest - vores gæster.
          Derfor lader vi Sport Compass -softwaren tage sig af kedelige gentagne opgaver
          som sociale medier, websted, opdateringer ... Jeg kan bestemt anbefale det!`,
  },
  {
    image: "default_profile_pic.png",
    name: "Keld Hilmar Hansen",
    position: "Café Runddelen",
    message: `Simon came here almost a year ago and showed me the BarPanel. I immediately
          liked it because I know how much time does making the sports schedule take.
          I don’t have to go online to different sites to discover matches and TV channels.
          I don’t have to manually write down the schedule. I don’t have to try to create a poster...`,
  },
  {
    image: "default_profile_pic.png",
    name: "Freddys Bar ApS",
    position: "Freddys Bar",
    message: `I have 4 businesses to run, I don't have time for marketing.
          If I wanted to do that, I would need to hire a new person. But I don’t
          have to, because I'm using Sport Compass. These guys know what is going
          on in the industry, and how to execute opportunities. They even helped
          me with a new website once we re-open after COVID.
          With the BarPanel software, I save time and money.`,
  },
  {
    image: "default_profile_pic.png",
    name: "Cafe Ludwigsen",
    position: "Cafe Ludwigsen",
    message: `Sport Compass makes organising our schedule, events etc.
          a lot easier and is very easy and intuitive to use!
          Highly recommended for any bar/cafe/restaurant with sport on tv!`,
  },
  {
    image: "default_profile_pic.png",
    name: "Roger Andersson",
    position: "Malmö Sportbar",
    message: `Sparar oss tid och vi undviker missar!
          Flödet på sociala medier blir informativt
          Jag ger tummen`,
  },
  {
    image: "default_profile_pic.png",
    name: "John Conaty",
    position: "Globe Irish Pub",
    message: `Jump on a call with Sport Compass guys and have a look at what they have.
          Great software, makes our lives here at
          The Globe Irish Pub in Copenhagen so much easier!`,
  },
  {
    image: "default_profile_pic.png",
    name: "Martin Jansson",
    position: "Shooters Jönköping",
    message: `Så bra program för att administrera kommande matcher till min sportbar.
          Blev mycket positivt överraskad. Rekommenderas starkt!`,
  },
];

const TestimonialsCaroussel = () => {
  return (
    <>
      <Carousel
        partialVisible={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="transform 0.8s ease-in-out"
        transitionDuration={800}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["desktop"]}
        dotListClass="custom-dot-list-style"
        itemClass="testimonial__testimony-wrapper"
      >
        {testimonials.map((item, idx) => {
          return (
            <TestimonialElement
              image={item.image}
              name={item.name}
              position={item.position}
            >
              {item.message}
            </TestimonialElement>
          );
        })}
      </Carousel>
    </>
  );
};

function TestimonialElement(props) {
  return (
    <div className="testimonial__testimony d-flex flex-column justify-content-between">
      <img
        src={getImage("quotationmark.svg")}
        alt="quotationmark"
        className="testimonial__testimony__quotation align-self-start"
      />
      <p className="fs-1 mt-auto mb-auto">"{props.children}"</p>
      <div className="mt-auto">
        <div className="border-bottom w-100 mb-1" />
        <div className="row align-items-center">
          <img
            // style={{
            //   height: "19px",
            //   paddingRight: "21px"
            // }}
            className="testimonial__testimony__image pr-2"
            src={getImage("testimonial-pics/" + props.image)}
            alt={props.image}
          />
          <div>
            <span className="testimonial__testimony__name">{props.name}</span>
            <br />
            <span>{props.position}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function getImage(path) {
  return require("../assets/images/" + path);
}

export default TestimonialsCaroussel;
